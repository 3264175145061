/* General Styles */
.beacon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    width: 100%; /* Ensure it takes full width */
    box-sizing: border-box;
  }
  
  /* Header Styles */
  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .subscribe-button,
  .share-button {
    background-color: white;
    border: none;
    border-radius: 20px;
    padding: 8px 15px;
    font-size: 14px;
    color: black;
    cursor: pointer;
  }
  
  /* Profile Section */
  .profile-section {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .username {
    font-size: 24px;
    font-weight: bold;
    color: white;
  }
  
  .subtitle {
    font-size: 14px;
    color: #fff;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
  }
  
  .social-icons img {
    width: 20px;
    height: 20px;
  }
  
  /* Content Section */
  .content-section {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
    flex-direction: column;
  }
  
  .circular-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 15px;
  }
  
  /* Button Section */
  .button-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .button {
    background-color: white;
    border: none;
    border-radius: 10px;
    padding: 15px;
    font-size: 16px;
    color: black;
    text-decoration: none;
    width: 100%; /* Use full width on mobile */
    text-align: center;
    box-sizing: border-box;
  }
  
  .button:hover {
    background-color: #E0E0E0;
  }
  
  /* Media Section */
  .media-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
  }
  
  .twitch {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    text-align: center;
  }
  
  .spotify iframe {
    border-radius: 10px;
    width: 100%; /* Ensure iframe scales on mobile */
  }
  
  /* Responsive Design */
  @media (min-width: 768px) {
    /* Tablet and larger screens */
  
    .profile-pic {
      width: 120px;
      height: 120px;
    }
  
    .username {
      font-size: 28px;
    }
  
    .subtitle {
      font-size: 16px;
    }
  
    .social-icons img {
      width: 25px;
      height: 25px;
    }
  
    .circular-image {
      width: 100px;
      height: 100px;
    }
  
    .button {
      font-size: 18px;
      padding: 15px 30px;
      width: 80%; /* Adjust for larger screens */
    }
  
    .content-section {
      flex-direction: row; /* Align image and buttons side by side on larger screens */
      justify-content: space-between;
      max-width: 768px; /* Control max width for better scaling */
    }
  }
  
  @media (max-width: 480px) {
    /* Mobile-specific styles */
  
    .profile-pic {
      width: 80px;
      height: 80px;
    }
  
    .username {
      font-size: 20px;
    }
  
    .subtitle {
      font-size: 12px;
    }
  
    .social-icons img {
      width: 18px;
      height: 18px;
    }
  
    .button {
      font-size: 14px;
      padding: 12px;
    }
  
    .circular-image {
      width: 70px;
      height: 70px;
    }
  
    .button-section {
      width: 100%;
    }
  
    .button {
      width: 100%; /* Use full width on mobile */
    }
  
    iframe {
      width: 100%; /* Ensure iframes take full width */
    }
  }
  